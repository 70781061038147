<template>
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="34"
      y="5"
      width="14"
      height="13"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M41.0001 5C44.8727 5 48.0001 7.91536 48.0001 11.5C48.0001 15.096 44.8605 18 41.0001 18C37.1275 18 34.0001 15.0846 34.0001 11.5C33.9879 7.90402 37.1275 5 41.0001 5ZM41.0001 15.7391C43.4561 15.7391 45.4546 13.8373 45.4546 11.5C45.4546 9.16275 43.4561 7.26086 41.0001 7.26086C38.5441 7.26086 36.5456 9.16275 36.5456 11.5C36.5456 13.8373 38.532 15.7391 41.0001 15.7391Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path d="M25.25 26.125H56.75V-3.125H25.25V26.125Z" fill="#4A4A4A" />
    </g>
    <mask
      id="mask1"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="54"
      height="54"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.2673 2.07185C29.4135 0.804482 31.0224 0.12123 32.7527 0.12123L44.5776 0C47.1012 0 49.5147 0.914693 51.2449 2.7551C53.0853 4.48531 54 6.89877 54 9.42244L53.8898 21.2584C53.7796 22.8673 53.1955 24.4763 51.9392 25.7437L23.6718 54L0 30.3282L28.2673 2.07185ZM23.8543 50.5895L50.3568 24.087C51.1566 23.2872 51.6168 22.2574 51.6168 21.1179L51.7263 9.47175C51.7263 7.6421 50.9265 5.81246 49.6666 4.44297C48.2971 3.07348 46.4675 2.27369 44.5282 2.27369L32.8821 2.38324C31.7426 2.38324 30.7128 2.84339 29.913 3.64318L3.41052 30.1457L23.8543 50.5895Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1)">
      <path
        d="M-8.4375 62.4375H62.4375V-8.4375H-8.4375V62.4375Z"
        fill="#4A4A4A"
      />
    </g>
  </svg>
</template>
