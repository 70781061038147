<template>
  <th-page-wrapper>
    <!-- Tile edit -->
    <item-editor
      v-if="currentItem"
      :item="currentItem"
      :details="currentItemDetails"
      @item-set-requested="handleItemSetRequested"
      @close-requested="handleEditorCloseRequested"
    />

    <el-container class="th-container">
      <el-main v-loading="loading" class="device-holder flex justify-center">
        <el-col
          class="device-container"
          :xs="24"
          :sm="24"
          :md="16"
          :lg="16"
          :xl="16"
        >
          <el-row
            class="screen-holder"
            :class="{ 'is-frame': showFrame, 'is-not-frame': !showFrame }"
          >
            <four-by-three-container>
              <el-row class="screen-content">
                <el-col :span="16">
                  <el-container>
                    <el-header>
                      <div v-if="currentTab" class="current-tab-header-items">
                        <!-- <div
                          class="setting-label"
                          v-text="$t('pages.favourites.tab_input.label')"
                        /> -->
                        <el-input
                          v-model="currentTab.name"
                          class="tab-name-input"
                          size="small"
                          :placeholder="
                            $t('pages.favourites.tab_input.placeholder')
                          "
                          @update:modelValue="handleTabNameChange"
                        >
                          <template #suffix>
                            <el-icon><Edit /></el-icon>
                          </template>
                        </el-input>
                      </div>
                    </el-header>
                    <el-main class="tabs-container">
                      <el-tabs
                        ref="tabs"
                        :model-value="getCurrentTabId(currentTab)"
                        class="tabs-holder"
                        tab-position="bottom"
                        addable
                        closable
                        type="card"
                        @update:modelValue="handleTabChange"
                        @tab-add="handleNewTab"
                        @tab-remove="handleRemoveTab"
                      >
                        <div
                          v-if="!form.tabs || !form.tabs.length"
                          class="no-tabs"
                        >
                          <span v-text="$t('pages.favourites.no_tab')" />
                        </div>
                        <el-tab-pane
                          v-for="(tab, i) in form.tabs"
                          :key="tab.client_id"
                          :label="
                            tab.name ||
                            `${$t('pages.favourites.untitled_tab')} (${i + 1})`
                          "
                          :name="tab.client_id"
                        >
                          <favourite-tab
                            v-if="
                              getCurrentTabId(currentTab) === localCurrentTabId
                            "
                            v-model="form.tabs[i].items"
                            class="tile-set"
                            @edit-requested="handleEditRequested"
                            @clear-requested="handleClearRequested"
                            @change-requested="handleChangeRequested"
                          />
                        </el-tab-pane>
                      </el-tabs>
                    </el-main>
                  </el-container>
                </el-col>
                <el-col :span="8">
                  <favourite-selector
                    :favourites="favourites"
                    @new-favourite-requested="handleNewFavourite"
                    @remove-favourite-requested="handleRemoveFavourite"
                    @rename-favourite-requested="handleRenameFavourite"
                    @close-requested="$thModal.hide('favourite-selector')"
                  />

                  <el-container class="settings-holder">
                    <el-main class="mt-10 space-y-4">
                      <div class="flex items-end">
                        <!-- Tileset -->
                        <div class="flex-grow">
                          <th-input-title
                            :title="
                              $t('pages.favourites.favourite_select.label')
                            "
                          />
                          <remote-search-select
                            v-model="localCurrentFavouriteId"
                            resource="favourites"
                            fetch-handler="getAll"
                            :placeholder="
                              $t(
                                'pages.favourites.favourite_select.placeholder'
                              )
                            "
                            :modify-query="
                              (q) => ({
                                query: {
                                  limit: 50,
                                  deleted: false,
                                  q
                                }
                              })
                            "
                            @update:modelValue="handleFavouriteSelect"
                          />
                        </div>

                        <el-button
                          plain
                          icon="Edit"
                          class="el-button--primary-icon flex-shrink-0 ml-4"
                          @click="$thModal.show('favourite-selector')"
                        />
                      </div>

                      <!--Locations -->
                      <div>
                        <th-input-title
                          for="locations"
                          :title="$t('common.forms.labels.locations')"
                        />
                        <available-in
                          id="locations"
                          class="w-full"
                          :model-value="locations"
                          :resources="resources"
                          :show-items-limit="2"
                          @update:modelValue="handleAvailableInInput"
                        />
                      </div>
                    </el-main>
                  </el-container>
                </el-col>
              </el-row>
            </four-by-three-container>
          </el-row>
        </el-col>
      </el-main>

      <el-footer>
        <div class="actions">
          <el-switch
            class="mr-4"
            :model-value="pageMode !== 'none'"
            :active-text="$t('pages.favourites.show_frame')"
            @change="updatePageMode"
          />

          <el-button @click="reset">
            {{ $t('common.interactions.buttons.reset') }}
          </el-button>

          <el-button
            v-permissions="{
              scopes: [
                'utilities:favourites:create',
                'utilities:favourites:update'
              ]
            }"
            type="primary"
            data-testid="save-button"
            @click="save"
          >
            {{ $t('common.interactions.buttons.save') }}
          </el-button>
        </div>
      </el-footer>
    </el-container>
  </th-page-wrapper>
</template>

<script>
import pick from 'just-pick'
import omit from 'just-omit'
import typeOf from 'just-typeof'
import Sortable from 'sortablejs'
import th from '@tillhub/javascript-sdk'
import cloneDeep from 'clone-deep'
import compare from 'just-compare'
import * as uuid from 'uuid'
import pWhilst from 'p-whilst'
import { mapGetters } from 'vuex'
import AvailableIn from '@/components/available-in'
import ItemEditor from './components/item-editor'
import FavouriteTab from './components/tab'
import FavouriteSelector from './components/favourite-selector'
import FourByThreeContainer from './components/4-3-container'
import RemoteSearchSelect from '@/components/select/remote-search'

function genDefault() {
  return {
    id: null,
    tabs: [],
    registers: null,
    locations: null,
    branch_groups: null,
    name: null,
    client_id: uuid.v4(),
    active: true
  }
}

export default {
  name: 'Favourites',
  metaInfo() {
    return {
      title: this.$t('pages.favourites.title')
    }
  },
  components: {
    FavouriteTab,
    FavouriteSelector,
    FourByThreeContainer,
    AvailableIn,
    ItemEditor,
    RemoteSearchSelect
  },
  data: () => ({
    favourites: [],
    stagingFavourites: [],
    showFavouriteSelector: false,
    loading: false,
    currentItem: null,
    localCurrentFavouriteId: null,
    localCurrentTabId: null,
    form: genDefault(),
    resources: {}
  }),
  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation'
    }),
    currentTabId: {
      get() {
        if (this.localCurrentTabId) return this.localCurrentTabId
        if (!this.form.tabs || !this.form.tabs.length) return

        return this.form.tabs[0].client_id
      },
      set(v) {
        this.localCurrentTabId = v
      }
    },
    currentTab() {
      if (!this.form) return null
      const tab = this.form.tabs.find((item) => {
        return item.client_id === this.currentTabId
      })

      return tab
    },
    hasChanges() {
      return !compare(this.stagingFavourites, this.favourites)
    },
    pageMode() {
      return (this.$store.getters['Config/getPageMode'] || {}).favourites
    },
    showFrame() {
      return this.pageMode !== 'none'
    },
    locations() {
      return {
        locations: this.form.locations,
        branch_groups: this.form.branch_groups
      }
    }
  },
  watch: {
    currentTabId() {
      this.attachSortableTabs()
    },
    currentLocation() {
      this.getFavourites()
    }
  },
  async beforeMount() {
    await this.getFavourites()
    this.fetchResources()
  },
  methods: {
    async fetchResources() {
      try {
        const {
          branchesV1 = [],
          branchGroups = []
        } = await this.$resourceFetch('branchesV1', 'branchGroups')
        this.resources = { branches: branchesV1, branchGroups }
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: this.$t('common.error.action.read.multiple', {
            resources: this.$t('common.resource.resource.plural')
          })
        })
      }
    },
    updatePageMode(value) {
      const v = value ? 'device' : 'none'

      this.$store.dispatch('Config/setLocalConfigurationValue', {
        path: 'pageMode.favourites',
        value: v
      })
    },
    async handleNext(nextFn) {
      if (typeOf(nextFn) !== 'function') return []
      let _next = nextFn
      let results = []
      await pWhilst(
        () => typeOf(_next) === 'function',
        async () => {
          const { data, next } = await _next()
          results = [...results, ...data]
          _next = next || undefined
        }
      )
      return results
    },
    async getFavourites(activeItemId, activeTabId) {
      const errorMessage = this.$t('common.error.action.read.multiple', {
        resources: this.$t('common.resource.favourite.plural')
      })
      this.loading = true
      try {
        const { data, next } = await th.favourites().getAll({
          active: true,
          deleted: false,
          branch: this.currentLocation
        })

        const nextResults = await this.handleNext(next)
        const favourites = [...data, ...nextResults]
        // cloning so we don't modify data object. Also sanitize missing arrays
        const tempFavourites = cloneDeep(favourites).map((favourite) => {
          if (!Array.isArray(favourite.tabs)) {
            favourite.tabs = []
          }

          favourite.tabs = favourite.tabs.map((tab) => {
            if (!tab.order_index) tab.order_index = 0 // Fixes the null order_index

            if (!Array.isArray(tab.items)) {
              tab.items = []
            }

            return tab
          })

          return favourite
        })

        // lets presort the tabs and their items
        tempFavourites.forEach((fav) => {
          // sort tabs
          fav.tabs.sort(this.sortByOrderIndex)

          // sort items of tabs
          fav.tabs.forEach((tab) => {
            tab.items.sort(this.sortByOrderIndex)
          })
        })
        this.favourites = tempFavourites
        this.setActiveItem(activeItemId, activeTabId)

        // Set default
        const _default = this.favourites.find((item) => item.name === 'default')
        if (!_default) {
          this.localCurrentFavouriteId = this.favourites[0].id
          this.handleItem()
          return
        }
        this.localCurrentFavouriteId = _default.id
        this.handleItem()
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: errorMessage
        })
      } finally {
        this.loading = false
      }
    },
    reset() {
      this.setActiveItem(this.localCurrentFavouriteId)
    },
    sortByOrderIndex(a, b) {
      return a.order_index - b.order_index
    },
    save() {
      if (this.form.id) {
        return this.update()
      } else {
        return this.create()
      }
    },
    async update(obj, cb) {
      const successMessage = this.$t('common.success.action.update.single', {
        resource: this.$t('common.resource.favourite.singular')
      })
      const errorMessage = this.$t('common.error.action.update.single', {
        resource: this.$t('common.resource.favourite.singular')
      })
      this.loading = true
      const payload = obj || this.form

      try {
        const inst = th.favourites()
        const { data } = await inst.update(payload.id, omit(payload, ['id']))
        this.getFavourites(data.id, this.localCurrentTabId)
        this.$message({
          type: 'success',
          message: successMessage
        })
        if (cb) {
          // do not fetch on cb. Likely the caller wants to do that
          return cb(null, data)
        }
      } catch (err) {
        this.$logException(err, { message: errorMessage })
        if (cb) return cb(err)
      } finally {
        this.loading = false
      }
    },
    async create(obj, cb) {
      const successMessage = this.$t('common.success.action.create.single', {
        resource: this.$t('common.resource.favourite.singular')
      })
      const errorMessage = this.$t('common.error.action.create.single', {
        resource: this.$t('common.resource.favourite.singular')
      })
      this.loading = true
      const payload = omit(obj || this.form, ['id'])

      try {
        const inst = th.favourites()
        const { data } = await inst.create(payload)
        this.getFavourites(data.id)
        this.$message({
          type: 'success',
          message: successMessage
        })
        if (cb) return cb()
      } catch (err) {
        this.$logException(err, { message: errorMessage })
        if (cb) return cb(err)
      } finally {
        this.loading = false
      }
    },
    async delete(id, cb) {
      const successMessage = this.$t('common.success.action.delete.single', {
        resource: this.$t('common.resource.favourite.singular')
      })
      const errorMessage = this.$t('common.error.action.delete.single', {
        resource: this.$t('common.resource.favourite.singular')
      })
      this.loading = true

      const _id = id || this.form.id

      try {
        const inst = th.favourites()
        await inst.delete(_id)
        this.getFavourites()
        this.$message({
          type: 'success',
          message: successMessage
        })
        if (cb) return cb()
      } catch (err) {
        this.$logException(err, { message: errorMessage })
        if (cb) return cb(err)
      } finally {
        this.loading = false
      }
    },

    onFavouritesEdit(newFavs) {
      const newCurrentFavId = this.getNewCurrentFavouriteId(newFavs)
      this.stagingFavourites = newFavs
      this.currentFavouriteId = newCurrentFavId
    },
    handleEditRequested(tab, item, details) {
      this.currentItem = item
      this.currentItemDetails = details

      this.$nextTick(() => {
        this.$thModal.show('item-editor')
      })
    },
    handleClearRequested(_, item) {
      const favourite = this.$deepClone(this.form)
      favourite.tabs = favourite.tabs.map((tabItem) => {
        if (tabItem.client_id === this.localCurrentTabId) {
          tabItem.items = tabItem.items.filter(
            (i) => i.client_id !== item.client_id
          )
        }
        return tabItem
      })

      this.form = favourite
    },
    handleChangeRequested(items) {
      const favourite = this.$deepClone(this.form)

      favourite.tabs = favourite.tabs.map((tabItem) => {
        if (tabItem.client_id === this.localCurrentTabId) {
          tabItem.items = items
        }
        return tabItem
      })

      this.$nextTick(() => {
        this.form = favourite
      })
    },
    handleEditorCloseRequested() {
      this.$thModal.hide('item-editor')
    },
    handleTabChange(v) {
      this.localCurrentTabId = v
    },
    handleTabNameChange(v) {
      const favourite = this.$deepClone(this.form)
      favourite.tabs = favourite.tabs.map((item) => {
        if (item.client_id === this.localCurrentTabId) {
          item.name = v
        }
        return item
      })

      this.form = favourite
    },
    handleNewTab() {
      const favourite = this.$deepClone(this.form)
      let orderIndex = 0
      if (favourite.tabs.length) {
        orderIndex =
          Math.max.apply(
            Math,
            favourite.tabs.map(function (o) {
              return o.order_index
            })
          ) + 1
      }

      const tab = {
        name: null,
        order_index: orderIndex,
        items: [],
        client_id: uuid.v4()
      }
      favourite.tabs.push(tab)

      this.form = favourite

      this.localCurrentTabId = tab.client_id
    },
    handleRemoveTab(clienId) {
      const favourite = this.$deepClone(this.form)
      favourite.tabs = favourite.tabs.filter(
        (item) => item.client_id !== clienId
      )

      // we want to move back to the left instead of to the beginning
      // sort mutates
      this.localCurrentTabId = [...favourite.tabs]
        .sort((a, b) => a.order_index - b.order_index)
        .reverse()[0].client_id

      this.form = favourite
    },
    handleItemSetRequested(localPayload, localItem, type) {
      // in order to map decent state we will manually go into
      // items and override data and then override the whole form object
      const favourite = this.$deepClone(this.form)

      const objectId = localPayload.id

      favourite.tabs.forEach((tab, tabIndex) => {
        if (this.localCurrentTabId === tab.client_id) {
          const _tab = favourite.tabs[tabIndex]

          const itemIndex = _tab.items.findIndex(
            (item) => item.client_id === localItem.client_id
          )

          if (itemIndex > -1) {
            _tab.items[itemIndex] = {
              ..._tab.items[itemIndex],
              type,
              object_id: objectId,
              client_id: uuid.v4()
            }
          } else {
            _tab.items.push({
              ...localItem,
              type,
              object_id: objectId,
              client_id: uuid.v4()
            })
          }

          favourite.tabs[tabIndex] = _tab
        }
      })

      this.form = favourite
      // make sure to close and purge state of the editor, as otherwise applying again will not work
      this.handleEditorCloseRequested()
    },
    async handleNewFavourite(name) {
      this.create({ ...genDefault(), name }, (err) => {
        if (!err) {
          this.$thModal.hide('favourite-selector')
        }
      })
    },
    handleRemoveFavourite(favourite, id) {
      this.delete(id, (err) => {
        if (!err) {
          this.$thModal.hide('favourite-selector')
        }
      })
    },

    handleRenameFavourite(favourite, id, name) {
      // TODO: until the API overides everyting in PUT we will patch the whole object
      this.update({ ...favourite, name }, (err) => {
        if (!err) {
          this.getFavourites(this.localCurrentFavouriteId)
          this.$thModal.hide('favourite-selector')
        }
      })
    },
    getCurrentTabId(tab) {
      if (!tab) return null
      return tab.client_id
    },
    handleFavouriteSelect(v) {
      this.setActiveItem(v)
    },
    setActiveItem(activeItem, activeTab) {
      if (activeItem) {
        this.localCurrentFavouriteId = activeItem
        this.handleItem(activeItem, activeTab)
        return
      } else {
        this.localCurrentFavouriteId = null
      }
    },
    handleItem(activeitem, activeTab) {
      const obj = this.favourites.find(
        (item) => item.id === (activeitem || this.localCurrentFavouriteId)
      )

      this.localCurrentFavouriteId = obj.id

      // if activeTab is present use it. Fallback to the first one.
      // the second case likely occures when we update
      this.localCurrentTabId = activeTab || (obj.tabs[0] || {}).client_id

      // TODO: handle sorting and client id assignment
      this.form = pick(this.$deepClone(obj), [
        'id',
        'tabs',
        'registers',
        'locations',
        'branch_groups',
        'name',
        'client_id',
        'active'
      ])
    },
    attachSortableTabs() {
      const el = this.$refs.tabs.$el.querySelector('.el-tabs__nav')
      Sortable.create(el, {
        animation: 200,
        filter: '.el-icon-close',
        onEnd: this.onTabDragEnd
      })
    },
    onTabDragEnd(ev) {
      const { oldIndex, newIndex } = ev
      const favourite = this.$deepClone(this.form)

      // we gonna build an empty array with the new item in the right place
      const newArr = Array.from({ length: this.form.tabs.length }, (_, i) => {
        if (i === newIndex) return this.form.tabs[oldIndex]
        return null
      })
      // let's get the remaining items without the one that is already in place
      const fillups = this.form.tabs.filter((_, i) => i !== oldIndex)

      let k = 0
      // let's fill up the null positions with the remaining. If the current item
      // the the new position we skip.
      for (let i = 0; i < newArr.length; i++) {
        if (newArr[i]) {
          continue
        }
        // If not assign and update the counter for the remainders

        newArr[i] = fillups[k]
        k++
      }

      // attach the new array to the tabs. Also assign their order index based
      // on occurence in array
      favourite.tabs = newArr.map((item, i) => ({ ...item, order_index: i }))

      this.form = favourite
    },
    handleAvailableInInput(value) {
      this.form.locations = value.locations
      this.form.branch_groups = value.branch_groups
    }
  }
}
</script>

<style scoped>
.th-container {
  height: 100%;
}

.device-container {
  height: 100%;
}

.tile-set {
  /* padding: 6px; */
  width: 100%;
  height: 100%;
}

.tabs-holder {
  overflow: visible;
  width: 100%;
  height: 100%;
  border: unset;
  box-shadow: unset;
  margin: 0;
  padding: 0;
}

.tabs-holder :deep(.no-tabs) {
  user-select: none;
  background: #e0e0e1;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
}

.tabs-holder :deep(.el-tabs__content) {
  overflow: visible;
  height: calc(100% - 30px);
}

.tabs-holder :deep(.el-tabs__header) {
  user-select: none;
  box-sizing: border-box;
  height: 30px;
}

.tabs-holder :deep(.el-tabs__item) {
  box-sizing: border-box;
  height: 30px;
  line-height: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 959px) {
  .tabs-holder :deep(.el-tabs__content) {
    height: calc(100% - 30px);
  }

  .tabs-holder :deep(.el-tabs__header) {
    height: 30px;
  }

  .tabs-holder :deep(.el-tabs__item) {
    height: 30px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 960px) {
  .tabs-holder :deep(.el-tabs__content) {
    height: calc(100% - 30px);
  }

  .tabs-holder :deep(.el-tabs__header) {
    height: 30px;
  }

  .tabs-holder :deep(.el-tabs__item) {
    height: 30px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 1200px) {
  .tabs-holder :deep(.el-tabs__content) {
    height: calc(100% - 35px);
  }

  .tabs-holder :deep(.el-tabs__header) {
    height: 35px;
  }

  .tabs-holder :deep(.el-tabs__item) {
    height: 35px;
    line-height: 35px;
  }
}

.tabs-holder
  :deep(.el-tabs--card)
  > .el-tabs__header
  .el-tabs__item.is-closable:hover {
  /* overrides some weird animation */
  padding: 0 !important;
}

.tabs-holder :deep(.el-tab-pane) {
  height: 100%;
  width: 100%;
}

.tabs-holder :deep(.el-tabs__header.is-bottom *) {
  color: white;
}

.tabs-holder :deep(.el-tabs__header.is-bottom) {
  margin: 0;
  background-color: #232e3d;
}

.tabs-holder :deep(.el-tabs__header.is-bottom .el-tabs__item.is-active) {
  background-color: #3e4958;
}

.tabs-holder :deep(.el-tabs__nav-next),
.tabs-holder :deep(.el-tabs__nav-prev) {
  line-height: 35px;
  font-size: 14px;
}

.tabs-holder
  :deep(.el-tabs__header.is-bottom .el-tabs__item.is-active::before) {
  box-sizing: border-box;
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 3px solid #279ff6;
  background-color: #279ff6;
  z-index: 1;
}

.tabs-holder :deep(.el-tabs__header *),
.tabs-holder :deep(.el-tabs__header) {
  border: 0 !important;
}

.tabs-holder :deep(.el-icon-close:hover) {
  background-color: transparent;
}

.tabs-holder :deep(.el-tabs__new-tab) {
  color: var(--secondary-color);
  font-size: 18px;
  padding: 0 10px;
  margin: 0;
  width: auto;
  line-height: 35px;
  height: auto;
}

.tabs-holder :deep(.el-tabs__header .el-tabs__item) {
  border-top: 1px solid transparent;
}

.tabs-holder :deep(.el-tabs__header .el-tabs__item.is-active) {
  border-top-color: white;
  background-color: white;
}

.tab-name-input-holder {
  margin-bottom: 10px;
  width: 746px;
  display: flex;
  justify-content: center;
}

.ipad > .tabs-holder {
  position: absolute;
  top: 122px;
  left: 114px;
}

.ipad > .tab-name-input-holder {
  position: absolute;
  left: 114px;
  top: 80px;
}

.ipad > .favourite-select-holder {
  position: absolute;
  left: 875px;
  top: 200px;
}

.tab-name-input {
  width: unset;
}

.tab-name-input :deep(input) {
  text-align: center;
}

.favourite-selector :deep(input) {
  text-align: center;
}

.buttons-holder {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px 0;
}

.buttons-holder-item {
  flex: 0 0 auto;
  margin: 10px;
}

.setting-label {
  font-size: x-large;
  font-weight: bold;
  text-align: center;
  color: var(--secondary-color);
}

.header-row {
  display: flex;
  align-items: center;
  align-content: center;
}

.actions {
  display: flex;
  justify-content: flex-end;
  justify-items: flex-end;
  align-content: center;
  align-items: center;
  height: 100%;
  padding: 0px 20px;
}

.screen-content {
  box-sizing: border-box;
}

.is-not-frame .screen-content {
  background: white;
  border: 1px solid #dcdfe6;
}

.tabs-container {
  overflow: visible;
  margin: 0;
  padding: 0;
}

.screen-holder {
  /* height: 100%; */
  position: relative;
}

.screen-holder,
.screen-holder > * {
  width: 100%;
}

.screen-content,
.screen-content > * {
  height: 100%;
}

.device-image {
  display: block;
  margin: 0;
  padding: 0;
}

.device-image.y-border {
  width: 100%;
  height: auto;
}

.device-image.x-border {
  width: 100%;
  height: 100%;
}

.is-frame {
  background: url('https://storage.googleapis.com/tillhub-dashboard/images/devices/ipad/full_upside.png');
  background-size: 100% 100%;
  display: block;
  position: relative;
  padding: 3%;
}

.is-frame > * {
  background-color: white;
}

.current-tab-header-items {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
}

.settings-holder .header-content {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
}

.settings-holder .edit-container {
  display: flex;
  justify-content: center;
  justify-items: center;
}
</style>
