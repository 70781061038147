<template>
  <svg
    width="65"
    height="54"
    viewBox="0 0 65 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Product with Link">
      <g id="Linked">
        <path
          id="Stroke-7-Copy"
          d="M54 38V38.65H54.65V38H54ZM54 38V38.65H54.65V38H54ZM54 38V37.35H53.35V38H54ZM54 38V37.35H53.35V38H54ZM54.65 38V29H53.35V38H54.65ZM53.35 29V38H54.65V29H53.35ZM54 37.35H43V38.65H54V37.35ZM43 38.65H54V37.35H43V38.65ZM53.35 38V49H54.65V38H53.35ZM54.65 49V38H53.35V49H54.65ZM54 38.65H65V37.35H54V38.65ZM65 37.35H54V38.65H65V37.35Z"
          fill="black"
        />
        <g id="Group-11-Copy">
          <g id="Fill-9-Clipped">
            <g id="Fill-9 Clipped">
              <mask
                id="mask0"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                x="35"
                y="5"
                width="14"
                height="14"
              >
                <path
                  id="path-1"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M42.0001 5C45.8727 5 49.0001 8.13962 49.0001 12C49.0001 15.8726 45.8605 19 42.0001 19C38.1275 19 35.0001 15.8604 35.0001 12C34.9879 8.12741 38.1275 5 42.0001 5ZM42.0001 16.5652C44.4561 16.5652 46.4546 14.517 46.4546 12C46.4546 9.48296 44.4561 7.43478 42.0001 7.43478C39.5441 7.43478 37.5456 9.48296 37.5456 12C37.5456 14.517 39.532 16.5652 42.0001 16.5652Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0)">
                <path
                  id="Fill-9"
                  d="M26.25 27.75H57.75V-3.75H26.25V27.75Z"
                  fill="black"
                />
              </g>
            </g>
          </g>
        </g>
        <g id="Group-16-Copy">
          <g id="Fill-14-Clipped">
            <g id="Fill-14 Clipped">
              <mask
                id="mask1"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="54"
                height="54"
              >
                <path
                  id="path-3"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M28.2673 2.07185C29.4135 0.804482 31.0224 0.12123 32.7527 0.12123L44.5776 0C47.1012 0 49.5147 0.914693 51.2449 2.7551C53.0853 4.48531 54 6.89877 54 9.42244L53.8898 21.2584C53.7796 22.8673 53.1955 24.4763 51.9392 25.7437L23.6718 54L0 30.3282L28.2673 2.07185ZM23.8543 50.5895L50.3568 24.087C51.1566 23.2872 51.6168 22.2574 51.6168 21.1179L51.7263 9.47175C51.7263 7.6421 50.9265 5.81246 49.6666 4.44297C48.2971 3.07348 46.4675 2.27369 44.5282 2.27369L32.8821 2.38324C31.7426 2.38324 30.7128 2.84339 29.913 3.64318L3.41052 30.1457L23.8543 50.5895Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask1)">
                <path
                  id="Fill-14"
                  d="M-8.4375 62.4375H62.4375V-8.4375H-8.4375V62.4375Z"
                  fill="black"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
