<template>
  <th-modal
    name="item-editor"
    width="800px"
    :title="$t('pages.favorites.edit-modal.title')"
    @close="onCancel"
  >
    <div class="p-6 bg-th-light-blue">
      <!-- Product details -->
      <div
        v-if="!isNew && !!localDetails && localType === 'product'"
        class="mb-4 flex items-center"
      >
        <div class="image-container">
          <th-image
            v-if="productImage"
            cover
            width="100%"
            height="100%"
            position="center"
            :src="productImage"
          />
          <div
            class="tile-color"
            :style="{
              backgroundColor: localDetails.default_tile_color || 'white'
            }"
          />
        </div>

        <div class="item-text" :span="16">
          <h3 class="details-name" v-text="localDetails.name" />
          <p
            class="details-summary"
            v-text="
              localDetails.summary ||
              $t('common.data.no_data_for', {
                property: $t(
                  'pages.products.edit.form.properties.summary.label'
                )
              })
            "
          />
          <p
            class="details-description"
            v-text="
              localDetails.description ||
              $t('common.data.no_data_for', {
                property: $t(
                  'pages.products.edit.form.properties.description.label'
                )
              })
            "
          />
        </div>
      </div>

      <!-- Product group details -->
      <div
        v-if="!isNew && !!localDetails && localType === 'product_group'"
        class="mb-4 flex items-center"
      >
        <div class="image-container">
          <th-image
            v-if="productGroupImage"
            cover
            width="100%"
            height="100%"
            position="center"
            :src="productGroupImage"
          />
          <div
            class="tile-color"
            :style="{ backgroundColor: localDetails.color || 'white' }"
          />
        </div>

        <div class="item-text">
          <h3 class="details-name" v-text="localDetails.name" />
          <p
            class="details-description"
            v-text="
              localDetails.description ||
              $t('common.data.no_data_for', {
                property: $t(
                  'pages.product_groups.edit.form.properties.description.label'
                )
              })
            "
          />
        </div>
      </div>

      <!-- Discount details -->
      <div
        v-if="!isNew && !!localDetails && localType === 'discount'"
        class="mb-4"
      >
        <h3 class="details-name" v-text="localDetails.name" />
      </div>

      <!-- Custom function details -->
      <div
        v-if="!isNew && !!localDetails && localType === 'custom_function'"
        class="mb-4"
      >
        <h3 class="details-name" v-text="localDetails.name" />
      </div>

      <!-- Voucher action details -->
      <div
        v-if="!isNew && !!localDetails && localType === 'voucher_action'"
        class="mb-4"
      >
        <h3 class="details-name">
          {{ $t('pages.favourites.item_selector.voucher_title') }} -
          {{ localDetails.name }}
        </h3>
      </div>

      <el-row :gutter="20">
        <!-- Search -->
        <el-col :span="12">
          <th-input-title title="Search" />
          <el-input
            v-model="searchText"
            :placeholder="
              $t('pages.favourites.item_selector.search_placeholder')
            "
            :loading="loading"
            prefix-icon="Search"
            @update:modelValue="handleSearch"
          />
        </el-col>

        <!-- Type -->
        <el-col :span="6">
          <th-input-title title="Type" />
          <el-select
            v-model="localType"
            class="w-full"
            @change="handleTypeChange"
          >
            <el-option
              v-for="type in types"
              :key="type.name"
              :value="type.value"
              :label="type.name"
            />
          </el-select>
        </el-col>
      </el-row>
    </div>

    <!-- Table -->
    <th-table
      :key="localType"
      v-loading="loading"
      :data="tableData"
      :columns="columns[localType]"
      class="bg-white items-table"
      can-click-row
      @row-clicked="onTableSelect"
    />

    <template #footer>
      <!-- Cancel -->
      <el-button @click="onCancel">
        {{ $t('common.interactions.buttons.cancel') }}
      </el-button>

      <!-- Apply -->
      <el-button :disabled="!selectedItem" type="primary" @click="onSelect">
        {{ $t('common.interactions.buttons.apply') }}
      </el-button>
    </template>
  </th-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import debounce from 'debounce'
import safeGet from 'just-safe-get'
import ThTable from '@/components/table'

export default {
  name: 'SelectionDialog',

  components: {
    ThTable
  },

  props: {
    item: {
      type: Object,
      required: false,
      default: undefined
    },
    details: {
      type: Object,
      required: false,
      default: undefined
    }
  },

  data() {
    return {
      types: [
        {
          name: this.$t('pages.favourites.item_selector.types.product'),
          value: 'product'
        },
        {
          name: this.$t('pages.favourites.item_selector.types.product_group'),
          value: 'product_group'
        },
        {
          name: this.$t('pages.favourites.item_selector.types.discount'),
          value: 'discount'
        },
        {
          name: this.$t('pages.favourites.item_selector.types.voucher_action'),
          value: 'voucher_action'
        },
        {
          name: this.$t('pages.favourites.item_selector.types.custom_function'),
          value: 'custom_function'
        }
      ],
      columns: {
        product: [
          {
            label: this.$t('common.headers.name.title'),
            key: 'name',
            visible: true,
            formatter: (value) => value.name || '-'
          },
          {
            label: this.$t('common.headers.type.title'),
            key: 'type',
            visible: true,
            formatter: (value) => this.translations[value.type] || '–'
          }
        ],
        product_group: [
          {
            label: this.$t('common.headers.name.title'),
            key: 'name',
            visible: true,
            formatter: (value) => value.name || '-'
          },
          {
            label: this.$t('common.headers.custom_id.title'),
            key: 'product_group_id',
            visible: true,
            formatter: (value) => value.product_group_id || '-'
          }
        ],
        discount: [
          {
            label: this.$t('common.headers.name.title'),
            key: 'name',
            visible: true,
            formatter: (value) => value.name || '-'
          },
          {
            label: this.$t('common.headers.amount.title'),
            key: 'amount',
            visible: true,
            formatter: (value) => value.amount || '-'
          },
          {
            label: this.$t('common.headers.currency.title'),
            key: 'currency',
            visible: true,
            formatter: (value) => value.currency || '-'
          },
          {
            label: this.$t('common.headers.rate.title'),
            key: 'rate',
            visible: true,
            formatter: (value) => value.rate || '-'
          },
          {
            label: this.$t('common.headers.group.title'),
            key: 'group',
            visible: true,
            formatter: (value) => value.group || '-'
          },
          {
            label: this.$t('common.headers.type.title'),
            key: 'type',
            visible: true,
            formatter: (value) => value.type || '-'
          },
          {
            label: this.$t('common.headers.value.title'),
            key: 'value',
            visible: true,
            formatter: (value) => value.value || '-'
          }
        ],
        voucher_action: [
          {
            label: this.$t('common.headers.name.title'),
            key: 'name',
            visible: true,
            formatter: (value) => value.name || '-'
          },
          {
            label: this.$t(
              'pages.favourites.item_selector.headers.lookup.title'
            ),
            key: 'lookup',
            visible: true,
            formatter: (value) => value.lookup || '-'
          },
          {
            label: this.$t('common.headers.type.title'),
            key: 'type',
            visible: true,
            formatter: (value) => value.type || '-'
          },
          {
            label: this.$t('common.headers.custom_id.title'),
            key: 'id',
            visible: true,
            formatter: (value) => value.id || '-'
          }
        ],
        custom_function: [
          {
            label: this.$t('common.headers.name.title'),
            key: 'name',
            visible: true,
            formatter: (value) => value.name || '-'
          },
          {
            label: this.$t(
              'pages.favourites.item_selector.headers.runtime.title'
            ),
            key: 'runtime',
            visible: true,
            formatter: (value) => value.runtime || '-'
          },
          {
            label: this.$t('common.headers.value.title'),
            key: 'type',
            visible: true,
            formatter: (value) => value.type || '-'
          }
        ]
      },
      searchText: '',
      tableData: [],
      loading: false,
      localType: safeGet(this.item, 'type') || 'product',
      localItem: this.item,
      localDetails: this.details,
      selectedItem: null
    }
  },

  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation'
    }),
    isNew() {
      if (!this.localItem) return true
      return this.localItem && !this.localDetails
    },
    showTableSelect() {
      if (this.searchText) return true
      if (this.isNew) return true

      // meaning, we are likely editing
      if (!this.isNew && this.tableData && this.tableData.length) {
        return true
      }

      return false
    },
    productImage() {
      return safeGet(this.localDetails, 'images.1x')
    },
    productGroupImage() {
      return safeGet(this.localDetails, 'images.1x')
    },
    translations() {
      return {
        composed_product: this.$t(
          'common.products.types.composed_product.title'
        ),
        linked: this.$t('common.products.types.linked.title'),
        linked_product: this.$t('common.products.types.linked_product.title'),
        product: this.$t('common.products.types.product.title'),
        variant: this.$t('common.products.types.variant.title'),
        variant_product: this.$t('common.products.types.variant_product.title'),
        voucher: this.$t('common.products.types.voucher.title')
      }
    }
  },

  watch: {
    item() {
      this.localItem = this.item
      this.localType = safeGet(this.item, 'type') || 'product'
    },
    details() {
      this.localDetails = this.details
    },
    currentLocation() {
      this.maybeLoadInitialData()
    }
  },

  mounted() {
    this.maybeLoadInitialData()
  },

  methods: {
    handleSearch: debounce(function (v) {
      this.asyncSearch(v)
    }, 300),
    onTableSelect(row) {
      this.selectedItem = row
      this.localDetails = row
      this.localItem = row
    },
    onSelect() {
      this.$emit(
        'item-set-requested',
        this.selectedItem,
        this.item,
        this.localType
      )
    },
    onCancel() {
      this.$emit('close-requested')
      this.clearData()
    },
    async searchProducts(text = '') {
      const { products = [] } = await this.$resourceFetch({
        resource: 'products',
        query: {
          q: text,
          sellable: true,
          limit: 50,
          types: [
            'composed_product',
            'linked_product',
            'linked',
            'product',
            'variant_product',
            'variant',
            'voucher'
          ],
          location_strict: this.currentLocation ? true : undefined,
          location: this.currentLocation
        }
      })
      return products
    },
    async searchDiscounts(text = '') {
      const { discounts } = await this.$resourceFetch('discounts')
      return discounts.filter(
        (i) => i.name && i.name.toLowerCase().includes(text.toLowerCase())
      )
    },
    async searchProductGroups(text = '') {
      const { productGroups } = await this.$resourceFetch('productGroups')
      return productGroups.filter(
        (i) => i.name && i.name.toLowerCase().includes(text.toLowerCase())
      )
    },
    async searchVoucherActions(text = '') {
      const { voucher_systems } = await this.$resourceFetch('voucher_systems')
      let actions = []
      voucher_systems.forEach((voucherSystem) => {
        actions = [...actions, ...(voucherSystem.actions || [])]
      })

      const lowText = text.toLowerCase()
      if (actions) {
        // looks ugly, but simply filters by items where action, context or id matches text
        return actions.filter(({ name, lookup, type, id }) => {
          return (
            (name && name.toLowerCase().includes(lowText)) ||
            (lookup && lookup.toLowerCase().includes(lowText)) ||
            (type && type.toLowerCase().includes(lowText)) ||
            id.toLowerCase().includes(lowText)
          )
        })
      }
    },
    async searchFunctions(text = '') {
      const { functions } = await this.$resourceFetch('functions')

      return functions.filter(
        (i) => i.name && i.name.toLowerCase().includes(text.toLowerCase())
      )
    },
    async asyncSearch(searchText) {
      this.loading = true

      try {
        switch (this.localType) {
          case 'product':
            this.tableData = await this.searchProducts(searchText)
            break
          case 'discount':
            this.tableData = await this.searchDiscounts(searchText)
            break
          case 'product_group':
            this.tableData = await this.searchProductGroups(searchText)
            break
          case 'voucher_action':
            this.tableData = await this.searchVoucherActions(searchText)
            break
          case 'custom_function':
            this.tableData = await this.searchFunctions(searchText)
            break
          default:
          // no-op
        }
      } catch (err) {
        this.$logException(err, {
          message: `search failed: ${err.message}`,
          trackError: false
        })
      } finally {
        // make table row order consistent across requests
        this.loading = false
      }
    },
    clearData() {
      this.tableData = []
      this.searchText = ''
      this.localItem = null
      this.localDetails = null
    },
    async maybeLoadInitialData() {
      switch (this.localType) {
        case 'product':
          // TODO handle getting products differently. deactivated due to (possibly) too many products
          this.tableData = await this.searchProducts()
          break
        case 'discount':
          this.tableData = await this.searchDiscounts()
          break
        case 'product_group':
          this.tableData = await this.searchProductGroups()
          break
        case 'voucher_action':
          this.tableData = await this.searchVoucherActions()
          break
        case 'custom_function':
          this.tableData = await this.searchFunctions()
          break
        default:
        // no-op
      }
      // TODO: consider some sorting
    },
    handleTypeChange(v) {
      this.localType = v
      this.clearData()
      this.maybeLoadInitialData()
    }
  }
}
</script>

<style scoped>
.image-container {
  min-width: 140px;
  max-width: 140px;
  height: 100px;
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color);
  overflow: auto;
  background: white;
  position: relative;
  margin-right: 1.5rem;
}

.items-table {
  min-height: 330px;
}

.details-name,
.details-summary,
.details-description {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
  margin: 0;
  margin-bottom: 0.2rem;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
</style>
