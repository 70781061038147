<template>
  <svg
    width="102"
    height="43"
    viewBox="0 0 102 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M29 18C29 15.7941 27.2129 14 25 14C22.7871 14 20.993 15.7871 21 18C21 20.2059 22.7871 22 25 22C27.2059 22 29 20.2129 29 18ZM27.5455 18C27.5455 19.4383 26.4034 20.6087 25 20.6087C23.5897 20.6087 22.4545 19.4383 22.4545 18C22.4545 16.5617 23.5966 15.3913 25 15.3913C26.4034 15.3913 27.5455 16.5617 27.5455 18Z"
      fill="#4A4A4A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.409 11.0718C18.3837 11.0718 17.4302 11.4767 16.751 12.2278L0 28.9722L14.0278 43L30.7788 26.2555C31.5233 25.5045 31.8694 24.551 31.9347 23.5976L32 16.5837C32 15.0882 31.458 13.658 30.3673 12.6327C29.342 11.542 27.9118 11 26.4163 11L19.409 11.0718ZM29.8411 25.2738L14.1359 40.9789L2.02105 28.8641L17.7262 13.1589C18.2002 12.685 18.8105 12.4123 19.4857 12.4123L26.3871 12.3474C27.5363 12.3474 28.6205 12.8213 29.4321 13.6329C30.1787 14.4444 30.6526 15.5287 30.6526 16.6129L30.5877 23.5143C30.5877 24.1895 30.315 24.7998 29.8411 25.2738Z"
      fill="#4A4A4A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M64 18C64 15.7941 62.2129 14 60 14C57.7871 14 55.993 15.7871 56 18C56 20.2059 57.7871 22 60 22C62.2059 22 64 20.2129 64 18ZM62.5455 18C62.5455 19.4383 61.4034 20.6087 60 20.6087C58.5897 20.6087 57.4545 19.4383 57.4545 18C57.4545 16.5617 58.5966 15.3913 60 15.3913C61.4034 15.3913 62.5455 16.5617 62.5455 18Z"
      fill="#4A4A4A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M54.409 11.0718C53.3837 11.0718 52.4302 11.4767 51.751 12.2278L35 28.9722L49.0278 43L65.7788 26.2555C66.5233 25.5045 66.8694 24.551 66.9347 23.5976L67 16.5837C67 15.0882 66.458 13.658 65.3673 12.6327C64.342 11.542 62.9118 11 61.4163 11L54.409 11.0718ZM64.8411 25.2738L49.1359 40.9789L37.0211 28.8641L52.7262 13.1589C53.2002 12.685 53.8105 12.4123 54.4857 12.4123L61.3871 12.3474C62.5363 12.3474 63.6205 12.8213 64.4321 13.6329C65.1787 14.4444 65.6526 15.5287 65.6526 16.6129L65.5877 23.5143C65.5877 24.1895 65.315 24.7998 64.8411 25.2738Z"
      fill="#4A4A4A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M99 17C99 14.7941 97.2129 13 95 13C92.7871 13 90.993 14.7871 91 17C91 19.2059 92.7871 21 95 21C97.2059 21 99 19.2129 99 17ZM97.5455 17C97.5455 18.4383 96.4034 19.6087 95 19.6087C93.5897 19.6087 92.4545 18.4383 92.4545 17C92.4545 15.5617 93.5966 14.3913 95 14.3913C96.4034 14.3913 97.5455 15.5617 97.5455 17Z"
      fill="#4A4A4A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M89.409 10.0718C88.3837 10.0718 87.4302 10.4767 86.751 11.2278L70 27.9722L84.0278 42L100.779 25.2555C101.523 24.5045 101.869 23.551 101.935 22.5976L102 15.5837C102 14.0882 101.458 12.658 100.367 11.6327C99.342 10.542 97.9118 10 96.4163 10L89.409 10.0718ZM99.8411 24.2738L84.1359 39.9789L72.0211 27.8641L87.7262 12.1589C88.2002 11.685 88.8105 11.4123 89.4857 11.4123L96.3871 11.3474C97.5363 11.3474 98.6205 11.8213 99.4321 12.6329C100.179 13.4444 100.653 14.5287 100.653 15.6129L100.588 22.5143C100.588 23.1895 100.315 23.7998 99.8411 24.2738Z"
      fill="#4A4A4A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 2H91.3708Z"
      fill="#4A4A4A"
    />
    <path d="M18 2H91.3708" stroke="#4A4A4A" stroke-linecap="square" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 1H90.3793Z"
      fill="#4A4A4A"
    />
    <path d="M18 1H90.3793" stroke="#4A4A4A" stroke-linecap="square" />
    <rect
      width="1"
      height="6"
      transform="matrix(1 0 0 -1 18 8)"
      fill="#4A4A4A"
      stroke="#4A4A4A"
      stroke-linecap="square"
    />
    <rect
      width="1"
      height="7"
      transform="matrix(1 0 0 -1 91 8)"
      fill="#4A4A4A"
      stroke="#4A4A4A"
      stroke-linecap="square"
    />
    <rect
      width="1"
      height="6"
      transform="matrix(1 0 0 -1 53 8)"
      fill="#4A4A4A"
      stroke="#4A4A4A"
      stroke-linecap="square"
    />
  </svg>
</template>
