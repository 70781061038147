<template>
  <svg
    width="76"
    height="55"
    viewBox="0 0 76 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icVariant">
      <g id="variant">
        <g id="variant group 4">
          <mask
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="57"
            y="6"
            width="14"
            height="14"
          >
            <path
              id="Clip 2"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M57.0001 13C57.0001 16.8605 60.1273 20 64.0001 20C67.8606 20 71.0001 16.8728 71.0001 13C71.0001 9.1395 67.8728 6 64.0001 6C60.1273 6 56.9878 9.12725 57.0001 13ZM59.5446 13C59.5446 10.4835 61.5448 8.43425 64.0001 8.43425C66.4553 8.43425 68.4556 10.4835 68.4556 13C68.4556 15.5183 66.4553 17.5658 64.0001 17.5658C61.5326 17.5658 59.5446 15.5183 59.5446 13Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0)">
            <path
              id="Fill 1"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M39.5 37.5H88.5V-11.5H39.5V37.5Z"
              fill="black"
            />
          </g>
        </g>
        <g id="variant group 3">
          <mask
            id="mask1"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="34"
            y="1"
            width="42"
            height="53"
          >
            <path
              id="second tag"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M54.635 1.11925C52.9305 1.11925 51.3458 1.79003 50.215 3.03222L51.838 4.57584C52.6245 3.79078 53.6406 3.33862 54.763 3.33862L66.2387 3.23097C68.1493 3.23097 69.9519 4.01603 71.3022 5.36091C72.5427 6.70412 73.3309 8.4995 73.3309 10.2965L73.2245 21.7263C73.2245 22.8443 72.7705 23.8562 71.9823 24.6413L45.8665 50.6527L35.8891 40.7136L34.1182 42.4791L45.6853 54L73.5404 26.2661C74.7793 25.0223 75.3547 23.4438 75.4627 21.8654L75.5725 10.2468C75.5725 7.77075 74.6712 5.40231 72.8587 3.70466C71.1525 1.89769 68.7746 1 66.2869 1L54.635 1.11925Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1)">
            <path
              id="Fill 4"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.73096 70.5625H92.2015V-15.5625H5.73096V70.5625Z"
              fill="black"
            />
          </g>
        </g>
        <g id="Variant group 2">
          <mask
            id="mask2"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="36"
            y="5"
            width="14"
            height="14"
          >
            <path
              id="Clip 8"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M36.0001 12C36.0001 15.8605 39.1273 19 43.0001 19C46.8606 19 50.0001 15.8728 50.0001 12C50.0001 8.1395 46.8728 5 43.0001 5C39.1273 5 35.9878 8.12725 36.0001 12ZM38.5446 12C38.5446 9.4835 40.5448 7.43425 43.0001 7.43425C45.4553 7.43425 47.4556 9.4835 47.4556 12C47.4556 14.5183 45.4553 16.5658 43.0001 16.5658C40.5326 16.5658 38.5446 14.5183 38.5446 12Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask2)">
            <path
              id="Fill 7"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18.5 36.5H67.5V-12.5H18.5V36.5Z"
              fill="black"
            />
          </g>
        </g>
        <g id="variant group 1">
          <mask
            id="mask3"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="55"
            height="55"
          >
            <path
              id="first tag"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M33.3592 0.12375C31.5958 0.12375 29.9578 0.819844 28.7908 2.10891L0 30.8911L24.1089 55L52.8997 26.2195C54.1802 24.9288 54.7748 23.2908 54.8883 21.6528L55 9.59578C55 7.02625 54.0684 4.56844 52.1933 2.80672C50.4316 0.931563 47.9738 0 45.4025 0L33.3592 0.12375ZM3.47359 30.7055L30.4666 3.71078C31.2812 2.89609 32.3314 2.42687 33.4916 2.42687L45.3527 2.31516C47.3275 2.31516 49.1906 3.12984 50.5863 4.52547C51.8702 5.91937 52.6831 7.7825 52.6831 9.64734L52.5731 21.5084C52.5731 22.6686 52.1056 23.7188 51.2875 24.5334L35.0264 40.7945L24.2945 51.5264L3.47359 30.7055Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask3)">
            <path
              id="Fill 10"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M-17 72H72V-17H-17V72Z"
              fill="black"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
