<template>
  <th-modal
    name="favourite-selector"
    :title="$t('pages.favourites.favourite_selector.title')"
    width="500px"
  >
    <div class="rectangle bg-white">
      <div class="list">
        <div v-if="!newActive" class="list-item">
          <div class="list-item-label" @click="addFavourite">
            {{ $t('pages.favourites.favourite_selector.add_favourite') }}
          </div>
          <!-- Add -->
          <el-button
            icon="Plus"
            class="el-button--text-icon"
            plain
            :disabled="nameEditActive"
            @click="addFavourite"
          />
        </div>
        <div v-if="newActive" class="list-item">
          <el-input
            v-model="currentNewName"
            :placeholder="
              $t('pages.favourites.favourite_selector.input_name_placeholder')
            "
          />
          <!-- Check -->
          <el-button
            icon="Check"
            class="el-button--text-icon"
            plain
            @click="submitNew"
          />
        </div>
        <div class="list-item">
          <div class="list-item-label">
            {{ $t('pages.favourites.favourite_selector.default.label') }}
          </div>
        </div>
        <template v-for="(favourite, i) in list">
          <div
            v-if="favourite.name !== 'default'"
            :key="favourite.id"
            class="list-item"
          >
            <!-- Name -->
            <el-input
              v-model="favourite.name"
              :disabled="nameEditActive && nameEditActiveItem !== favourite.id"
              :placeholder="
                $t('pages.favourites.favourite_selector.input_name_placeholder')
              "
              @focus="handleNameFocus(favourite)"
              @blur="handleNameBlur(favourite)"
            />
            <!-- Delete -->
            <el-button
              icon="Delete"
              class="el-button--text-icon"
              plain
              @click="handleRemove(favourite, i)"
            />
          </div>
        </template>
      </div>
    </div>

    <template #footer>
      <!-- Cancel -->
      <el-button el-button @click="onReset">
        {{ $t('common.interactions.buttons.cancel') }}
      </el-button>

      <!-- Apply -->
      <el-button :disabled="!applyEnabled" type="primary" @click="onSave">
        {{ $t('common.interactions.buttons.apply') }}
      </el-button>
    </template>
  </th-modal>
</template>

<script>
export default {
  name: 'FavouriteSelector',
  props: {
    favourites: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      nameEditActive: false,
      nameEditActiveItem: null,
      newActive: false,
      currentNewName: null
    }
  },
  computed: {
    list() {
      return this.favourites.filter((item) => item.name !== 'default')
    },
    applyEnabled() {
      return this.newActive || this.nameEditActiveItem // or others
    }
  },
  beforeMount() {
    this.reset()
  },
  methods: {
    addFavourite() {
      if (this.nameEditActive) return
      this.newActive = true
    },
    submitNew() {
      this.$emit('new-favourite-requested', this.currentNewName)
    },
    submitNewName() {
      const currentItem = this.favourites.find(
        (item) => item.id === this.nameEditActiveItem
      )

      this.nameEditActiveItem = null
      this.$emit(
        'rename-favourite-requested',
        currentItem,
        currentItem.id,
        currentItem.name
      )
    },
    reset() {
      this.currentNewName = null
      this.newActive = false
      this.nameEditActive = false
      this.nameEditActiveItem = null
    },
    onReset() {
      this.reset()
      this.$emit('close-requested')
    },
    onSave() {
      if (this.newActive) {
        this.submitNew()
        return
      }

      if (this.nameEditActiveItem) {
        this.submitNewName()
      }
    },
    handleRemove(favourite) {
      this.$emit('remove-favourite-requested', favourite, favourite.id)
    },
    handleNameFocus(favourite) {
      if (this.newActive) return
      this.nameEditActive = true
      this.nameEditActiveItem = favourite.id
    },
    handleNameBlur() {
      this.$nextTick(() => {
        this.nameEditActive = false
      })
    }
  }
}
</script>

<style scoped>
.list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1rem;
  border-bottom: solid 1px var(--border-color);
}

.list-item:last-child {
  border-bottom: 0 none;
}

.list-item > .el-button {
  margin-left: 10px;
}

.list-item-label {
  cursor: pointer;
  font-weight: bold;
  width: 100%;
  line-height: 40px;
}

.el-input :deep(.el-input__wrapper) {
  border: 0;
  padding: 0;
  box-shadow: none;
}

.rectangle {
  width: 100%;
  border-radius: var(--border-radius);
  border: solid 1px var(--border-color);
}

.hidden {
  visibility: hidden;
}

.dialog-body-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-content,
.footer-content {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-content {
  justify-content: flex-end;
}
</style>
