<template>
  <div class="favourite-tab-container">
    <draggable
      v-model="items"
      :item-key="getKey"
      class="tile-set"
      ghost-class="ghost"
      :animation="150"
      group="tiles"
    >
      <template #item="{ element }">
        <item
          class="item"
          :name="element.client_id"
          :item="element"
          @clear="clearTile(i)"
          @edit-requested="
            (v, details) => $emit('edit-requested', undefined, v, details)
          "
          @new-requested="(v) => $emit('new-requested', undefined, element)"
          @clear-requested="(v) => $emit('clear-requested', undefined, element)"
        />
      </template>
    </draggable>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'

import Item from './item'

export default {
  name: 'FavouriteTab',
  components: {
    Draggable,
    Item
  },
  props: {
    modelValue: {
      type: Array,
      required: true
    }
    // size: {
    //   type: Number,
    //   default: () => 25
    // }
  },
  data() {
    return {}
  },
  computed: {
    items: {
      get() {
        return Array.from(Array(25))
          .map((_, i) => ({ type: undefined, order_index: i + 1 }))
          .map((item) => {
            const remoteItem = this.modelValue.find(
              (i) => i.order_index === item.order_index
            )
            if (remoteItem) return { ...remoteItem }
            return item
          })
      },
      set(v) {
        // NOTE: the resulting order_index is the actual array index of this local state here
        const filtered = this.$deepClone(
          v
            .map((item, index) => ({ ...item, order_index: index + 1 }))
            .filter((item) => item.type)
        )
        this.$emit('change-requested', filtered)
      }
    }
  },
  beforeMount() {},
  methods: {
    getKey(item) {
      return `${item.client_id}-${item.order_index}`
    }
  }
}
</script>

<style scoped>
.tile-set {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: #e0e0e0;
  height: 100%;
  width: 100%;
}

.tile-set > * {
  flex-shrink: 1;
}

.ghost {
  opacity: 0;
}

.item {
  position: relative;
}
</style>
