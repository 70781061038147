<template>
  <div class="tile-wrap" @click.stop="handleItemClick">
    <div v-loading="loading" class="tile">
      <template v-if="item.type">
        <th-image
          v-if="imgUrl"
          cover
          width="100%"
          height="100%"
          position="center"
          class="tile-button bg-image"
          :src="imgUrl"
        />

        <div v-else class="tile-button bg-image empty-image empty">
          <empty-product v-if="item.type === 'product'" />
          <empty-variant-product v-if="item.type === 'variant_product'" />
          <empty-linked-product v-if="item.type === 'linked_product'" />
          <empty-product-group v-if="item.type === 'product_group'" />
          <empty-voucher
            v-if="
              item.type === 'voucher' ||
              item.type === 'voucher_action' ||
              item.type === 'system'
            "
          />
          <empty-discount v-if="item.type === 'discount'" />
        </div>

        <!-- NOTE badge has to be second item, otherwise strange visual glitch occurs when clicking it -->
        <el-button
          class="remove-badge"
          circle
          icon="Close"
          size="small"
          type="primary"
          @click.stop="$emit('clear-requested', item)"
        />
        <div class="tile-title-holder">
          <div v-if="details" class="tile-title" v-text="name" />
          <div v-else class="tile-title">
            <span>-</span>
          </div>
        </div>
      </template>
      <template v-else>
        <el-button
          class="tile-button"
          icon="Plus"
          @click="$emit('click', item)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import safeGet from 'just-safe-get'
import th from '@tillhub/javascript-sdk'
import EmptyProduct from './images/product'
import EmptyProductGroup from './images/product_group'
import EmptyVariantProduct from './images/variant_product'
import EmptyLinkedProduct from './images/linked_product'
import EmptyVoucher from './images/voucher'
import EmptyDiscount from './images/discount'

export default {
  name: 'Item',
  components: {
    EmptyProduct,
    EmptyProductGroup,
    EmptyVariantProduct,
    EmptyLinkedProduct,
    EmptyVoucher,
    EmptyDiscount
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      details: null,
      loading: false
    }
  },
  computed: {
    imgUrl() {
      let imgUrl

      if (this.item.type === 'product') {
        imgUrl = safeGet(this.details, ['images', '1x'])
      } else if (this.item.type === 'product_group') {
        imgUrl = safeGet(this.details, ['images', '1x'])
      } else if (this.item.type === 'voucher_action') {
        imgUrl = safeGet(this.details, ['product', 'images', '1x'])
      } else if (this.item.type === 'custom_function') {
        imgUrl = safeGet(this.details, [
          'configuration',
          'properties',
          'images',
          '1x'
        ])
      }

      if (!imgUrl) {
        return undefined
      }

      return imgUrl
    },
    name() {
      const name =
        safeGet(this.details, 'name') ||
        safeGet(this.details, 'product.name') ||
        safeGet(this.details, 'action') ||
        '-'
      return name
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    getTileStyle() {
      if (!this.imgUrl) {
        return {}
      }

      return {
        'background-image': `url('${this.imgUrl}')`
      }
    },
    clearTile() {
      this.$emit('clear', this.item)
    },
    handleItemClick() {
      if (this.item.type === 'empty') {
        this.$emit('new-requested', this.item)
        return
      }
      this.$emit('edit-requested', this.item, this.details)
    },
    async fetch() {
      this.loading = true
      try {
        this.details = await this.fetchDetails(this.item)
      } catch (err) {
        this.$logException(err, { trackError: false })
      } finally {
        this.loading = false
      }
    },
    async fetchDetails(item) {
      try {
        // NOTE: awaiting in switch case so we can catch errors here
        switch (item.type) {
          case 'product':
            return await this.getProductDetails(item.object_id)
          case 'product_group':
            return await this.getProductGroupDetails(item.object_id)
          case 'discount':
            return await this.getDiscountDetails(item.object_id)
          case 'voucher_action':
            return await this.getVoucherActionDetails(item.object_id)
          case 'custom_function':
            return await this.getCustomFunctionDetails(item.object_id)
          default:
          // no-op
        }
      } catch (e) {
        this.$logException(e, {
          trackError: false,
          message: this.$t('common.error.action.read.single', {
            resource: this.$t('common.resource.function.singular')
          })
        })
      }
    },
    /**
     * fetches details needed to properly display a product tile
     * @param {string} itemId
     */
    async getProductDetails(itemId) {
      const { data } = await th.products().get(itemId)
      return data
    },

    async getProductGroupDetails(itemId) {
      const { data } = await th.productGroups().get(itemId)
      return data
    },

    /**
     * fetches details needed to properly display a discount tile
     * @param {string} discountId
     */
    async getDiscountDetails(discountId) {
      const { data } = await th.discounts().get(discountId)
      return data
    },

    async getVoucherActionDetails(voucherActionId) {
      const { configurations } = await this.$resourceFetch({
        resource: 'configurations',
        query: { owner: 'self' }
      }) // can throw
      const voucherActions = safeGet(configurations, [0, 'voucher_actions'])
      if (!voucherActions)
        throw new Error('No voucher actions in configurations')
      const voucherAction = voucherActions.find(
        (action) => action.id === voucherActionId
      )
      if (!voucherAction) return
      const productResult = await th.products().get(voucherAction.product.id) // can throw
      const product = productResult.data
      if (!product) throw new Error('No product for voucher action')
      // we return the voucher action with the resolved product
      return { ...voucherAction, product }
    },
    async getCustomFunctionDetails(fnId) {
      const { data } = await th.functions().get(fnId)
      return data
    }
  }
}
</script>

<style scoped>
.tile-wrap {
  cursor: pointer;
  overflow: visible;
  position: relative;
  width: 20%;
  height: 20%;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
}

.tile {
  overflow: visible;
  position: relative;
  width: 90%;
  height: 90%;
  background-color: white;
  box-shadow: 0 0 3px grey;
}

.tile-button {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;

  background-size: cover;
  background-position: center;
}

.tile-title-holder {
  overflow: hidden;
  z-index: 1;
  position: absolute;
  bottom: 0;
  height: 50%;
  width: 100%;
  display: block;
  /* margin-left: 10%;
  margin-right: 10%; */
  background: white;

  box-sizing: border-box;
}

.tile-title {
  box-sizing: border-box;
  position: relative;
  height: 60%;
  margin: 10%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  color: var(--secondary-color);
  font-size: 0.8rem;
}

/* NOTE: those breakpoints need to match the once set in the HTML */
@media only screen and (max-width: 512px) {
  .tile-title {
    font-size: 0.6rem;
  }
}

@media only screen and (min-width: 768px) {
  .tile-title {
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 992px) {
  .tile-title {
    font-size: 0.7rem;
  }
}

@media only screen and (min-width: 1200px) {
  .tile-title {
    font-size: 0.8rem;
  }
}

.remove-badge {
  position: absolute;
  top: -14px;
  left: -14px;
  opacity: 0.5;
  z-index: 15;
  height: 28px;
  width: 28px;
}

.tile-wrap:hover .remove-badge {
  opacity: 1;
}

.el-button + .el-button {
  margin: 0;
}

.icon-large {
  font-size: x-large;
}

.empty-image {
  background: #e9eaeb;
}

.empty::before {
  content: '\200B';
}

.tile-button.bg-image.empty-image {
  height: 50%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.tile-button.bg-image.empty-image > * {
  height: 70%;
  margin-left: auto;
  margin-right: auto;
}
</style>
