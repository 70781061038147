<template>
  <div class="aspect-container ratio4-3">
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="css" scoped>
.ratio4-3:before {
  padding-top: 75%;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.aspect-container {
  position: relative;
  display: inline-block;
}
.aspect-container:before {
  content: '';
  display: block;
}
</style>
